import React from "react";

import { ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
export const CreateWalletPassword = ({submit}) => {
    const [values, setValues] = React.useState({

        password: '',
        confirmPassword: '',
        showConfirmPassword: false,
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    return (

        <Grid container sx={{ pt: 5 }} className='bodys'>

            <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"center"}>
                    <Card
                        sx={{ width: 600, borderRadius: "0px" }}
                        elevation={0}
                        raised
                    >
                        <CardContent sx={{ p: 5 }}>
                            <Box sx={{ mt: 4 }}>
                                <Typography fontWeight={"bold"} variant="h6" sx={{ mb: 1 }}>
                                    Protect your wallet with a password
                                </Typography>

                                <Typography variant="body2">
                                    The password you enter encrypts your private key and gives
                                    access to your funds. Please store your password properly.
                                    E7 does not keep nor able to restore it for security
                                    reasons.
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 3 }}>
                                <FormControl variant="outlined" sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>

                                    <OutlinedInput

                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        onChange={handleChange('password')}

                                        placeholder="Password"
                                        label="Password"
                                    />
                                </FormControl>

                                <FormControl variant="outlined" sx={{ width: "100%", mt: 4 }}>
                                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>

                                    <OutlinedInput

                                        type={values.showConfirmPassword ? 'text' : 'password'}
                                        value={values.confirmPassword}
                                        onChange={handleChange('confirmPassword')}
                                        label="Confirm Password"
                                        placeholder="Confirm Password"

                                    />
                                </FormControl>
                            </Box>
                            <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                sx={{ mt: 4 }}
                            >
                                <Box display={"flex"}>
                                    <ArrowBackIos color="primary" />
                                    <Typography color={"primary"}>Back</Typography>
                                </Box>
                                <Button variant="contained" size="large" onClick={submit}>
                                    {" "}
                                    i've written it down
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>

        </Grid>

    )
}