import * as React from "react";
import { ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export const Earn = () => {
    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const firstItem = [
        {
            name: "Wallets",
            to: "wallets",
        },
        {
            name: "Buy & Sell",
            to: "buysell",
        },
        {
            name: "Earn",
            to: "earn",
        },
        {
            name: "Setting",
            to: "setting",
        },
    ];

    return (
        <div className="">
            <AppNav firstItem={firstItem} />

            <div className="e7">
                <Grid container sx={{ pt: 7 }} className="bodys" >
                    <Grid item xs={12} >
                        <Box display={"flex"} justifyContent={"center"}>
                            <Card sx={{ minWidth: "100%" }}>
                                <CardContent
                                    sx={{ height: "600px", }}
                                >
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                            <TabList
                                                onChange={handleChange}
                                                aria-label="lab API tabs example"
                                            >
                                                <Tab label="Start New Staking" value="1" />

                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">History</TabPanel>

                                    </TabContext>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>

                </Grid>
            </div>
        </div>
    );
};
