import { ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React from "react";
import { useHistory } from "react-router-dom";

export default function RestoreWallet() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const firstItem = [{
    name: "Create Wallet",
    to: "createwallet"
  }, {
    name: "Restore or Import",
    to: "restorewallet"
  }]
  const history = useHistory()

  return (
    <div className="">
      <AppNav firstItem={firstItem} />
      <div className="e7">
        <Grid container>
          <Grid item xs={12} sx={{ mt: 5 }}>
            <Box display={"flex"} justifyContent={"center"}>
              <Card
                sx={{ width: 600, borderRadius: "0px" }}
                elevation={0}
                raised
              >
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Restore From E7 Backup" value="1" />
                      <Tab label="Import By Currency" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <CardContent>
                      <Typography
                        fontWeight={"bold"}
                        textAlign="center"
                        variant="h6"
                      >

                      </Typography>
                      <Typography textAlign={"center"} variant="body2">
                        You will be able to restore your wallet by using a
                        Backup if you are a user of Guarda Wallet.
                      </Typography>

                      <Box display="flex" justifyContent={"end"}>
                        <Button size="small">Enter backup text</Button>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection={"column"}
                        justifyContent="center"
                        alignItems={"center"}
                        sx={{
                          border: "dashed 1px #dddddd",
                          height: 200,
                          borderRadius: "5px",
                        }}
                      >
                        <img
                          src="https://guarda.co/app/9905fdd26ac8f33d2a6c091e57c26111.svg"
                          width={"110px"}
                        />
                        <Button
                          sx={{ mt: 4, bgcolor: "#81C784" }}
                          variant="contained"
                          component="label"
                          size="small"
                        >
                          Browse
                        </Button>
                      </Box>
                      <TextField
                        placeholder="Enter Password"
                        label="Backup password"
                        fullWidth
                        sx={{ mt: 2 }}
                      />
                      <Box
                        display={"flex"}
                        justifyContent="center"
                        sx={{ mt: 4 }}
                      >
                        <Button variant="contained">Restore</Button>
                      </Box>
                    </CardContent>
                  </TabPanel>
                  <TabPanel value="2">
                    <CardContent>
                      <Typography
                        fontWeight={"bold"}
                        variant="body1"
                        sx={{ mb: 1 }}
                      >
                        Import your funds
                      </Typography>
                      <Typography variant="subtitle2">
                        You can import the private keys from external wallets.
                        This will allow you to receive, send, purchase and
                        exchange crypto. Your private keys are held encrypted on
                        this browser local storage, not by a company.
                      </Typography>
                      <Box sx={{ bgcolor: "#108FF5", p: 2, mt: 4 }}>
                        <TextField
                          variant="outlined"
                          sx={{ bgcolor: "#fff" }}
                          fullWidth
                          placeholder="Select Currency"
                        />
                        <TextField
                          variant="outlined"
                          sx={{ bgcolor: "#fff", mt: 2 }}
                          fullWidth
                          placeholder="Private key, Mnemonic WIF XRP"
                        />
                        <Box
                          display="flex"
                          justifyContent={"space-between"}
                          sx={{ mt: 2 }}
                          alignContent="center"
                        >
                          <Box>
                            <Typography variant="body2" color={"#fff"}>
                              secure encryption
                            </Typography>
                          </Box>
                          <Button
                            sx={{
                              border: "1px solid #fff",
                              color: "#fff",
                              px: 2,
                            }}
                          >
                            add wallet
                          </Button>
                        </Box>
                      </Box>
                      <Box></Box>
                      <Box>
                        <Typography variant="h6" sx={{ mt: 2 }}>Wallets</Typography>

                        <Box display="flex" justifyContent={"center"} alignContent="center">
                          <Typography textAlign={"center"} variant="body2">No wallets have been added</Typography>
                        </Box>
                        <Divider sx={{ mt: 4 }} />
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          sx={{ mt: 4 }}
                        >
                          <Box display={"flex"}>
                            <ArrowBackIos color="primary" />
                            <Typography color={"primary"} sx={{ cursor: "pointer" }} onClick={() => history.goBack()}>Back</Typography>
                          </Box>
                          <Button variant="contained" size="large">
                            {" "}
                            Import
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </TabPanel>
                </TabContext>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
