import logo from './logo.svg';
import './App.css';
import { Home } from './Pages/Home/Index';
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import { Login } from './Pages/Login/Index';
import { Register } from './Pages/Register/Index';
import E7 from './Pages/App/Index';
import CreateWallet from './Pages/App/CreateWallet';
import RestoreWallet from './Pages/App/RestoreWallet';
import { Backup } from './Components/DownloadBackup/Index';
import { Wallets } from './Pages/App/Wallets';
import { BuySell } from './Pages/App/BuySell';
import { Earn } from './Pages/App/Earn';
import {SettingPage } from './Pages/App/Settings';



function App() {

  const light = {
    palette: {
      mode: "light",
      primary: {
        light: "#90CAF9",
        main: "#108FF5",
        dark: "#1565C0",
        contrastText: "#fff",
      },

      secondary: {
        light: "#8a44fd",
        main: "#510ac9",
        dark: "#000097",
        // contrastText: "#fff",
      },
      allVariants: {
        fontFamily: "'Montserrat', sans-serif",
        textTransform: "none",
      }
    },

  };

  let theme = createTheme(light);
  theme = responsiveFontSizes(theme);

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <HashRouter>
          <Router >
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/app">
                <E7 />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/createwallet">
                <CreateWallet />
              </Route>
              <Route exact path="/restorewallet">
                <RestoreWallet />
              </Route>
              <Route exact path="/downloadbackup">
                <Backup />
              </Route>
              <Route exact path="/wallets">
                <Wallets />
              </Route>
              <Route exact path="/buysell">
                <BuySell />
              </Route>
              <Route exact path="/earn">
                <Earn />
              </Route>
              <Route exact path="/setting">
                <SettingPage />
              </Route>
            </Switch>
          </Router>
        </HashRouter>

      </ThemeProvider>


    </div>
  );
}

export default App;
