import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import logo from '../../assets/logo.svg'
import { Avatar, Badge, Button, Drawer, IconButton, List, ListItem, ListItemText, Menu, MenuItem } from '@mui/material';
import './style.css'
import Fade from '@mui/material/Fade';

import { AccountBalanceOutlined, AccountBalanceWalletOutlined, KeyboardArrowDown, MenuRounded, Notifications } from '@mui/icons-material';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';


function ElevationScroll(props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 1 : 0,
        style: {
            backgroundColor: trigger ? "#fff" : "transparent",

        },


    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,

    window: PropTypes.func,
};





export default function Navbar({ children, props }) {


    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const nav = [

        {
            name: 'Get Started',
            to: '',
            handle: handleClick
        },

        {
            name: 'Buy Crypto',
            to: ''
        },
        {
            name: 'Exchange',
            to: ''
        },
        {
            name: 'Earn',
            to: ''
        },
        {
            name: 'Finance',
            to: ''
        }
    ];









    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar
                >
                    <Box className='custom_P'>
                        <Toolbar  >
                            <Link to={'/'}> <img src={logo} width="90" /></Link>
                            <Typography sx={{ flexGrow: 1, }}>
                            </Typography>

                            <Box sx={{ display: { xs: 'none', md: 'block', } }}>
                                {
                                    nav.map((item) => (
                                        <a className='cool-link'                     onMouseOver={item.handle} style={{ marginRight: '30px' }}>
                                            <Button sx={{color: "#363636"}}>{item.name}</Button>
                                        </a>

                                    ))

                                }



                            </Box>

                            <Menu
                                sx={{ mt: 2 }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        width: 200
                                    },
                                }}
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >

                                <Link to={"/app"} >
                                    <MenuItem sx={{
                                        color: '#000',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                            color: "#fff"


                                        },
                                    }}> 

                                    Online Wallet
                                    
                                    </MenuItem>
                                </Link>



                            </Menu>
                            <Typography sx={{ flexGrow: 1, }}>
                            </Typography>



                            <Box sx={{ display: { xs: 'none', md: 'block', } }}>
                              
                                <a href='/app' className='anchor' >
                                    <Button variant="contained" sx={{ fontSize: '17px', mr: 2, px: 4, textTransform: "capitalize" }} size="large" >Launch app </Button>
                                </a>
                               

                            </Box>
                            <IconButton
                                sx={{ display: { xs: 'block', md: 'none', } }}

                                onClick={() => setIsDrawerOpen(true)}

                            >
                                <MenuRounded />

                            </IconButton>
                        </Toolbar>
                        <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} PaperProps={{
                            sx: { width: "90%" },
                        }}>
                            <List >
                                {
                                    nav.map((item) => (

                                        <ListItem >
                                            <Link to={item.to}>
                                                <a className='anchor'>{item.name}</a>
                                            </Link>
                                        </ListItem>

                                    ))
                                }

                                <Box display="flex" flexDirection={"column"} sx={{
                                    px: 2, mt: 2, mb: 2
                                }}>
                                 

                                    <a href='/app' className='anchor' >
                                        <Button fullWidth  variant="contained" sx={{ fontSize: '12px', px: 2, textTransform: "capitalize" }} size="small" >Launch app </Button>
                                    </a>



                                </Box>



                            </List>
                        </Drawer>
                    </Box>
                </AppBar>

            </ElevationScroll>
            <Toolbar />

            <Box sx={{ mt: 4 }}>
                {children}

            </Box>


        </React.Fragment>
    );
}