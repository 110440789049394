import { ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import React, { useState } from "react";
import { CreateWalletPassword } from "../../Components/WalletPassword/Index";
import { CreateWalletProgress } from "../../Components/Core/createWalletProgress";

export default function CreateWallet() {
  const [walletPassword, setWalletPassword] = useState(true);
  const [createWalletProgress, setCreateWalletProgress] = useState(false);
  const [backup, setBackup] = useState(false);
  const [done, setDone] = useState(false);

  const handleSubmit = () => {
    setWalletPassword(false);
    setCreateWalletProgress(true);
  };
  const firstItem = [{
    name: "Create Wallet",
    to: "createwallet"
}, {
    name: "Restore or Import",
    to: "restorewallet"
}]

  return (
    <div className="">
      <AppNav firstItem={firstItem} />
      <div className="e7">
        {walletPassword ? <CreateWalletPassword submit={handleSubmit} /> : null}
        {createWalletProgress ? <CreateWalletProgress isDone={done} /> : null}
        
      </div>
    </div>
  );
}
