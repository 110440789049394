import { ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import "./style.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AppNav from "../../Layout/Appbar/Index";
import { useHistory } from "react-router-dom";
export const Backup = () => {
    const history = useHistory();

    const firstItem = [{
        name: "Create Wallet",
        to: "createwallet"
    }, {
        name: "Restore or Import",
        to: "restorewallet"
    }]

    return (
        <div className="">
            <AppNav firstItem={firstItem} />

            <div className="e7">
                <Grid container sx={{ pt: 5 }} className="bodys">
                    <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"center"}>
                            <Card
                                sx={{ width: 600, borderRadius: "0px" }}
                                elevation={0}
                                raised
                            >
                                <CardContent sx={{ p: 5 }}>
                                    <Box sx={{}}>
                                        <Typography
                                            fontWeight={"bold"}
                                            variant="h6"
                                            sx={{ mb: 1 }}
                                        >
                                            Backup is the key to your funds
                                        </Typography>

                                        <Typography variant="subtitle1">
                                            Please save your backup file and keep it properly as
                                            well as password. It ensures access to your funds.
                                        </Typography>
                                    </Box>
                                    <Typography variant="subtitle2">
                                        {" "}
                                        <span className="dot">.</span> Access to the wallet is
                                        possible only using both password and backup file.
                                    </Typography>

                                    <Typography variant="subtitle2">
                                        {" "}
                                        <span className="dot">.</span> Woin8 does not keep nor able
                                        to restore your backup and password. Only you <br />{" "}
                                        <Typography variant="" sx={{ ml: 3 }}>
                                            have access to your wallet.
                                        </Typography>
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {" "}
                                        <span className="dot">.</span>Never share it with anyone.
                                    </Typography>

                                    <Button
                                        fullWidth
                                        onClick={() => history.push("/wallets")}
                                        variant="contained"
                                        sx={{ mt: 2, mb: 2 }}
                                        size="large"
                                    >
                                        Download Backup
                                    </Button>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}