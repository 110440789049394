import * as React from "react";
import { ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export const BuySell = () => {
 
  const firstItem = [
    {
      name: "Wallets",
      to: "wallets",
    },
    {
      name: "Buy & Sell",
      to: "buysell",
    },
    {
      name: "Earn",
      to: "earn",
    },
    {
      name: "Setting",
      to: "setting",
    },
  ];

  return (
    <div className="">
      <AppNav firstItem={firstItem} />

      <div className="e7">
        <Grid container sx={{ pt: 7 }} className="bodys" >
          <Grid item xs={12} md={7}>
            <Box display={"flex"} justifyContent={"center"}>
              <Card sx={{ minWidth: "100%" }}>
                <CardContent
                  sx={{ height: "600px", width: "400px" }}
                ></CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display={"flex"} justifyContent={"center"}>
              <Card sx={{ minWidth: "400px" }}>
                <CardContent sx={{ height: "400px" }}>
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
