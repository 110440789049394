import * as React from "react";
import { ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export const Wallets = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const firstItem = [
    {
      name: "Wallets",
      to: "wallets",
    },
    {
      name: "Buy & Sell",
      to: "buysell",
    },
    {
      name: "Earn",
      to: "earn",
    },
    {
      name: "Setting",
      to: "setting",
    },
  ];

  return (
    <div className="">
      <AppNav firstItem={firstItem} />

      <div className="e7">
        <Grid container sx={{ pt: 5 }} className="bodys" space={4}>
          <Grid item xs={12} md={5}>
            <Box display={"flex"} justifyContent={"center"}>
              <Card sx={{ maxWidth: "320px" }}>
                <CardContent
                  sx={{ height: "400px", width: "400px" }}
                ></CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box display={"flex"} justifyContent={"center"}>
              <Card sx={{ minWidth: "100%" }}>
                <CardContent sx={{ height: "400px" }}>
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="History" value="1" />
                          <Tab label="Recieve" value="2" />
                          <Tab label="Send" value="3" />
                          <Tab label="Exchange" value="4" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">History</TabPanel>
                      <TabPanel value="2">Recieve</TabPanel>
                      <TabPanel value="3">Send</TabPanel>
                      <TabPanel value="4">Exchange</TabPanel>
                    </TabContext>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
