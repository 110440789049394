import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import "./style.css";
import trustPilot from "../../assets/trust.png"

export const Hero = () => {
  return (
    <div className="container">
      <Grid
        container
        sx={{ mt: 3 }}

        justifyContent="space-between"
        alignItems={"center"}
        alignContent="center"
        className="bodys"
      >
        <Grid item sx={12} md={5}>
          <Box >
           <Box sx={{width: 400}}>
           <Typography fontWeight={"bold"} variant="h3" >
              Secure Crypto
              Wallet
            </Typography>
           </Box>
            <Typography variant="h6">Stake, exchange, earn and buy Bitcoin, Ethereum and thousands of other assets.</Typography>
          </Box>
          <Box sx={{ mt: 4, mb: 3 }}>
  

            <Button  variant="contained" size="large">
              <Typography variant="">Get Started</Typography>
            </Button>
          </Box>
          <Box>
            <img  src={trustPilot} alt="image" width={"150px"} />
          </Box>
        </Grid>
        <Grid item xs={12} md={7} sx={{ mb: 3 }}>
          <img src="https://guarda.com/assets/images/home-hero__devices.png" alt="image" width={"100%"} />
        </Grid>
      </Grid>
    </div>
  );
};
