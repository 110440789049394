import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Router, useHistory } from 'react-router-dom';


export const CreateWalletProgress = ({ isDone }) => {
    const [progress, setProgress] = React.useState(0);
    const history = useHistory();

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

       


        return () => {
            clearInterval(timer);

        };
    }, []);
    
    React.useEffect(() => {
        setTimeout(() => {
            history.push("/downloadbackup");
        }, 2000)
    }, [])

    return (
        <Grid container className='bodys'>
            <Grid xs={12}>

                <Box display={"flex"} alignContent="center" justifyContent={"center"} flexDirection="column" sx={{ mt: 5 }}>
                    <Typography textAlign={"center"} variant="body2">Creating Wallet</Typography>
                    <Box display="flex" justifyContent={"center"} sx={{ my: 4 }}>
                        <Box >
                            <CircularProgress />
                        </Box>
                    </Box>
                </Box>

            </Grid>
            <Grid xs={12}>
                <Box display={"flex"} justifyContent="center">
                    <Box sx={{ minWidth: "240px" }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                </Box>

            </Grid>

        </Grid>


    )
}