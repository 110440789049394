import { Apple, Brightness2, Google, Mode } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { QRCode } from "react-qrcode-logo";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./style.css";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.jpeg";

export const Login = () => {
  const [emailClick, setEmailClick] = useState(true);
  const [phoneClick, setPhoneClick] = useState(false);
  const [phone, setPhone] = useState(null);
  const history = useHistory();

  const con = [
    {
      name: "Email",
      value: emailClick,
      click: () => {setPhoneClick(false); setEmailClick(true)},
    },

    {
      name: "Phone Number",
      value: phoneClick,
      click: () => {setPhoneClick(true); setEmailClick(false)},
    },
  ];

  return (
    <Box sx={{ minHeight: "100vh" }} display="flex" flexDirection={"column"}>
      <Box display="flex" justifyContent={"space-between"} alignItems="center">
        <a href="/">
          <img src={logo} width="100px" alt="image" />
        </a>
        <Box display="flex" alignItems={"center"}>
          <span>English</span>
          <Brightness2 sx={{ ml: 1, mr: 2, fontSize: "13px" }} />
        </Box>
      </Box>

      <Grid container alignItems={"center"} className="body">
        <Grid item xs={12} md={6}>
          <Box sx={{ maxWidth: 420 }} display="flex" flexDirection={"column"}>
            <Typography fontWeight={"bold"} variant="h4">
              E7 Account Login
            </Typography>

            <Box display="flex" alignItems={"center"} sx={{ mt: 5 }}>
              {con.map((item) => (
                <Box
                  onClick={item.click}
                  sx={{
                    mr: 6,
                    cursor: "pointer",
                    bgcolor: item.value ? "#F5F5F5" : null,
                    py: item.value ? 1 : null,
                    px: item.value ? 4 : null,
                  }}
                >
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      color: item.value ? "#000" : "#727C8C",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </Box>

            {emailClick ? (
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems="start"
                sx={{ mt: 4 }}
              >
                <Typography>Email</Typography>
                <TextField fullWidth />
              </Box>
            ) : (
              <Box sx={{ mt: 4 }}>
                <Typography>Phone Number</Typography>
                <PhoneInput
                  country={"us"}
                  specialLabel
                  inputStyle={{ width: "100%" }}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
              </Box>
            )}
            <Box sx={{ mt: 4 }}>
              <Typography>Password</Typography>
              <TextField fullWidth />
            </Box>
            <Box sx={{ mt: 4 }}>
              <Button variant="contained" fullWidth size="large">
                Log In
              </Button>
            </Box>
            <Box
              sx={{ mt: 4 }}
              display="flex"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div
                style={{
                  width: "80px",
                  height: "2px",
                  backgroundColor: "#EAECEF",
                }}
              ></div>{" "}
              <Typography variant="body-2">or continue with</Typography>
              <div
                style={{
                  width: "80px",
                  height: "2px",
                  backgroundColor: "#EAECEF",
                }}
              ></div>
            </Box>

            <Box
              sx={{ mt: 4 }}
              display="flex"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Box
                display="flex"
                alignItems={"center"}
                sx={{ px: 4, py: 2, mr:1, bgcolor: "#EAECEF" }}
              >
                {" "}
                <Google sx={{ mr: 1, color: "red" }} /> Google
              </Box>
              <Box
                display="flex"
                alignItems={"center"}
                sx={{ px: 4, py: 2, bgcolor: "#EAECEF" }}
              >
                {" "}
                <Apple sx={{ mr: 1 }} /> Apple
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography
                color="primary"
                sx={{ cursor: "pointer", mb: 1 }}
                fontWeight="bold"
                fontSize={"13px"}
              >
                Forgot Password?
              </Typography>
              <Typography
                color="primary"
                sx={{ cursor: "pointer" }}
                fontWeight="bold"
                fontSize={"13px"}
                onClick={() => history.push("/register")}
              >
                Create e7 account
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <QRCode value="under construction" logoImage={logo} />
            <Typography fontWeight={"bold"} fontSize="15px">
              Log in with QR code
            </Typography>
            <Typography textAlign={"center"} sx={{ mt: 4 }}>
              Scan this code with the{" "}
              <span style={{ color: "#1F75FE" }}>
                E7 mobile <br /> app
              </span>{" "}
              to log in instantly.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
