import {  Brightness2,  } from "@mui/icons-material";
import { Button, Grid, TextField, Typography, Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import logo from "../../assets/logo.jpeg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./style.css";

export const Register = () => {
    const [emailClick, setEmailClick] = useState(true);
    const [phoneClick, setPhoneClick] = useState(false);
    const [phone, setPhone] = useState(null);
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };


    const con = [
        {
            name: "Email",
            value: emailClick,
            click: () => {setPhoneClick(false); setEmailClick(true)},
        },

        {
            name: "Phone Number",
            value: phoneClick,
            click: () => {setPhoneClick(true); setEmailClick(false)},
        },
    ];

    return (
        <Box
            sx={{ minHeight: "100vh" }}
            display="flex"
            flexDirection={"column"}
        >
            <Box display="flex" justifyContent={"space-between"} alignItems="center">
        

              <a href="/">  <img src={logo} width="100px" alt="image" /></a>
                <Box display="flex" alignItems={"center"}>
                    <span>English</span>
                    <Brightness2 sx={{ ml: 1, mr: 2, fontSize: "13px" }} />
                </Box>
            </Box>
            <Grid container alignItems={"center"} className="body">
                <Grid item xs={12} md={6}>
                    <Box sx={{ maxWidth: 420 }} display="flex" flexDirection={"column"}>
                        <Typography fontWeight={"bold"} variant="h4">
                            Create Personal Account

                        </Typography>



                        <Box display="flex" alignItems={"center"} sx={{ mt: 5 }}>
                            {con.map((item) => (
                                <Box
                                    onClick={item.click}
                                    sx={{
                                        mr: 6,
                                        cursor: "pointer",
                                        bgcolor: item.value ? "#F5F5F5" : null,
                                        py: item.value ? 1 : null,
                                        px: item.value ? 4 : null,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            cursor: "pointer",
                                            fontWeight: "bold",
                                            color: item.value ? "#000" : "#727C8C",
                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>



                        {emailClick ? (
                            <Box
                                display="flex"
                                flexDirection={"column"}
                                alignItems="start"
                                sx={{ mt: 4 }}
                            >
                                <Typography>Personal Email</Typography>
                                <TextField fullWidth />
                            </Box>
                        ) : (
                            <Box sx={{ mt: 4 }}>
                                <Typography>Phone Number</Typography>
                                <PhoneInput
                                    country={"us"}
                                    specialLabel
                                    inputStyle={{ width: "100%" }}
                                    value={phone}
                                    onChange={(phone) => setPhone(phone)}
                                />
                            </Box>
                        )}
                        <Box sx={{ mt: 4 }}>
                            <Typography>Password</Typography>
                            <TextField fullWidth />
                        </Box>
                        <Box sx={{ mt: 4 }}>
                            <Typography>Referral ID (Optional)</Typography>
                            <TextField fullWidth />
                        </Box>

                        <Box sx={{ mt: 2 }} display="flex" alignItems={"center"}>
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <Typography fontSize={"13px"}>
                                I have read and agree to E7 <a href="/">Terms of Service</a> and <a href="/">Privacy Policy</a>.
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4 }}>
                            <Button variant="contained" fullWidth size="large">
                                Create Entity Account

                            </Button>
                        </Box>



                        <Box sx={{ mt: 5 }}>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Box>
    );
};
